import type { ClientSDK } from '@wix/installation-manager-client-sdk';
import type { IInstaller } from '@wix/app-market-core';
import type { ReadOnlyExperiments } from '@wix/wix-experiments';
import type { IHttpClient } from '@wix/http-client';
import type { i18n } from '@wix/wix-i18n-config';

export class Installer implements IInstaller {
  private readonly clientSDK: ClientSDK;
  private readonly installedVersionOverride?: string;
  private readonly referredByAppId?: string;
  private readonly experiments: ReadOnlyExperiments;
  private readonly i18n: i18n;
  private readonly httpClient: IHttpClient;

  constructor({
    clientSDK,
    installedVersionOverride,
    referredByAppId,
    experiments,
    httpClient,
    i18n,
  }: {
    clientSDK: ClientSDK;
    installedVersionOverride?: string;
    referredByAppId?: string;
    experiments: ReadOnlyExperiments;
    httpClient: IHttpClient;
    i18n: i18n;
  }) {
    this.clientSDK = clientSDK;
    this.installedVersionOverride = installedVersionOverride;
    this.referredByAppId = referredByAppId;
    this.experiments = experiments;
    this.httpClient = httpClient;
    this.i18n = i18n;
  }

  installApp: IInstaller['installApp'] = ({
    appId,
    shareUrlId,
    consentOpeningMethod,
    onSuccess,
    onFailure,
    correlationId,
  }) => {
    if (
      this.experiments?.enabled('spec.dcc.newInstallerAppMarketStandalone') ||
      shareUrlId
    ) {
      import('@wix/app-installer-client').then(({ installApp, TenantType }) => {
        installApp({
          appId,
          tenantType: TenantType.SITE,
          version: this.installedVersionOverride,
          essentials: {
            httpClient: this.httpClient,
            i18n: this.i18n,
          },
          shareUrlId,
          onProvisionSuccess: ([{ instanceId }]) => onSuccess(instanceId),
          correlationId,
        })
          .then(([{ instanceId }]) => onSuccess(instanceId))
          .catch(onFailure);
      });
    } else {
      this.clientSDK.installApp({
        appDefId: appId,
        openMethod: consentOpeningMethod,
        version: this.installedVersionOverride,
        onInstallationSuccess: ({ instanceId }) => {
          onSuccess?.(instanceId);
        },
        onInstallationFail: ({ reason: failureReason }) => {
          onFailure?.(failureReason);
        },
        referredByAppId: this.referredByAppId,
      });
    }
  };
}
