import type { GoogleAnalytics } from '@wix/app-market-analytics';
import { isSSR } from '@wix/app-market-services';

export function initGoogleAnalytics(
  googleAnalytics: GoogleAnalytics,
  gTag?: string,
) {
  if (gTag) {
    const isAnalyticsConsentApproved =
      !isSSR() &&
      window.consentPolicyManager?.getCurrentConsentPolicy().policy?.analytics;
    if (isAnalyticsConsentApproved) {
      googleAnalytics.initialize(gTag);
      return googleAnalytics;
    }
  }

  return undefined;
}
