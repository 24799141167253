import React, { startTransition } from 'react';
import { hydrateRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './components/App';
import { ExperimentsProvider } from '@wix/wix-experiments-react';
import { getI18nInstance } from './i18next/i18next-client';
import { ResourcesCTX } from '@wix/marketplace-component';
import { Hydrate, QueryClientProvider } from '@tanstack/react-query';
import { createQueryClient } from '@wix/app-market-core';

const baseURL = window.__BASEURL__;
const locale = window.__LOCALE__;
const experiments = window.__STORE_EXPERIMENTS__;
const marketplaceStaticsUrl = window.__MARKET_PLACE_STATIC_URL__;
const manageAppsStaticsUrl = window.__MANAGE_APPS_STATIC_URL__;
const ssrMemo = window.__SSR_MEMO__;
const resources = window.__RESOURCES__;
const consentPolicy = window.__CONSENT_POLICY__;

window.consentPolicyManager?.init({
  baseUrl: 'https://www.wix.com/market',
  consentPolicy,
});

if (window.wixTagManager) {
  const hostNames = window.wixTagManager.HOST_NAMES;
  window.wixTagManager &&
    window.wixTagManager.init(hostNames.APP_MARKET_STANDALONE, locale);
}

const i18nResources = window.__I18N_RESOURCES__;
const isMobile = window.__IS_MOBILE__;
const baseApiUrl = window.__BASE_API_URL__;
const countryCode = window.__COUNTRY_CODE__;
const userName = window.__USER_NAME__;
const userImage = window.__USER_IMAGE__;
const userId = window.__USER_ID__;
const dehydratedState = window.__REACT_QUERY_STATE__;
const artifactVersion = window.__ARTIFACT_VERSION__;

const queryClient = createQueryClient();

getI18nInstance(i18nResources, locale).then(({ i18n }) => {
  // Use startTransition for concurrent rendering
  startTransition(() => {
    hydrateRoot(
      document.getElementById('root'),
      <QueryClientProvider client={queryClient}>
        <Hydrate state={dehydratedState}>
          <Router basename={baseURL}>
            <ExperimentsProvider options={{ experiments }}>
              <ResourcesCTX.Provider value={resources}>
                <App
                  locale={locale}
                  baseURL={baseURL}
                  manageAppsStaticsUrl={manageAppsStaticsUrl}
                  marketplaceStaticsUrl={marketplaceStaticsUrl}
                  ssrMemo={ssrMemo}
                  routeBaseName={baseURL}
                  i18n={i18n}
                  isMobile={isMobile}
                  baseApiUrl={baseApiUrl}
                  countryCode={countryCode}
                  userName={userName}
                  userImage={userImage}
                  userId={userId}
                  artifactVersion={artifactVersion}
                />
              </ResourcesCTX.Provider>
            </ExperimentsProvider>
          </Router>
        </Hydrate>
      </QueryClientProvider>,
    );
  });
});
