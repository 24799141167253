enum CustomEvent {
  INSTALL_CLICK = 'install_button_click',
}

interface CustomEventsParamsMap {
  [CustomEvent.INSTALL_CLICK]: InstallClickParams;
}

export interface PageViewParams {
  referringPage: string | undefined;
  referringPageDetails: string | undefined;
  appPosition: string | undefined;
}

export interface DefaultParams {
  send_to: string;
  deviceType: 'desktop' | 'mobile';
  platform: string;
  user: string | undefined;
  siteId: string | undefined;
}

interface InstallClickParams {}

export { CustomEvent, CustomEventsParamsMap };
