import { AppBadgesType, type AppBadge } from '@wix/app-market-services';
import type Experiments from '@wix/wix-experiments';

export function hasBenefitOrBundleBadge({
  experiments,
  appBadges,
}: {
  experiments: Experiments;
  appBadges: AppBadge[];
}): boolean {
  return appBadges.some(
    (badge) =>
      (badge.badge === AppBadgesType.APP_BENEFIT &&
        experiments.enabled('specs.app-market.AppBenefits')) ||
      (experiments.enabled('specs.app-market.MoveBundlesToBenefits') &&
        badge.badge === AppBadgesType.PREMIUM_BENEFIT),
  );
}
