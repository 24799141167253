import React from 'react';
import { SectionHelper, Text } from '@wix/design-system';
import { useTranslation } from '../../../../../contexts/translations-context';
import { AppBenefitNotificationDataHooks } from './data-hooks';

export interface AppBenefitNotificationProps {
  appName: string;
}

export function AppBenefitNotification({
  appName,
}: AppBenefitNotificationProps) {
  const { t } = useTranslation();

  return (
    <SectionHelper
      appearance="success"
      fullWidth={true}
      title={t('app.page.benefit.app.notification.title', {
        appName,
      })}
      dataHook={AppBenefitNotificationDataHooks.container}
    >
      <Text
        size="small"
        weight="thin"
        dataHook={AppBenefitNotificationDataHooks.description}
      >
        {t('app.page.benefit.app.notification.subtitle')}
      </Text>
    </SectionHelper>
  );
}
