import type {
  IAppPricingPlan,
  IAppsPricingPlans,
  PricingPlanType,
  Prices,
} from './types';
import type { CurrencySettings, TaxDetails, IAppPlans } from '../apps-plans';
import { AppPricingPlan } from './app-pricing-plan';
import type { PricingPlans } from '../plans';
import { emptyAppPlansMock } from './pricing-plans-section-api.mock';
import type { SupportedAppStatuses } from '../../types';

/**
 * Represents a unified class for managing app plans prices.
 * Retrieves app plans from the app-plans service and pricingPlans from the plans service.
 * @class
 */

export class AppPricingPlans {
  constructor(
    private readonly appPlans: IAppPlans | undefined,
    private readonly pricingPlans: PricingPlans | undefined,
    private readonly status: SupportedAppStatuses,
  ) {}

  get taxSettings(): TaxDetails {
    return this.appPlans?.taxSettings ?? emptyAppPlansMock.taxSettings;
  }
  get currency(): CurrencySettings {
    return this.appPlans?.currency ?? emptyAppPlansMock.currency;
  }
  get appId(): string {
    return this.pricingPlans?.appId ?? '';
  }

  get isExternalPricing(): boolean {
    return this.appPlans?.isExternalBilling || false;
  }

  get isFreeApp(): boolean {
    return (
      this.plans.length === 0 ||
      (this.plans.length === 1 && this.plans[0].isFree)
    );
  }

  get hasFreePlan(): boolean {
    return this.plans.some((plan) => plan.isFree);
  }

  get hasDynamicPlan(): boolean {
    return this.pricingPlans?.hasDynamicPlan ?? false;
  }

  get hasMeteredPlanWithNoBaseFee(): boolean {
    return (
      (this.status === 'DRAFT'
        ? this.pricingPlans?.plans.some(
            (plan) =>
              plan.meterdBilling && Number(plan.meterdBilling?.baseFee) === 0,
          )
        : this.appPlans?.plans.some(
            (plan) => Number(plan.meterdBilling?.baseFee) === 0,
          )) || false
    );
  }

  get cheapestPlan():
    | {
        planType: PricingPlanType;
        prices: Prices;
      }
    | undefined {
    const validPlans = this.plans.filter(
      (plan) => plan.prices?.price !== undefined,
    );

    if (validPlans.length === 0) {
      return undefined;
    }

    const cheapestPlan = validPlans.reduce((cheapest, plan) => {
      const { discountPrice: currentDiscountPrice, price: currentPrice } =
        plan.prices ?? {};
      const { discountPrice: cheapestDiscountPrice, price: cheapestPrice } =
        cheapest.prices ?? {};

      // Determine the effective price for each plan, prioritizing discountPrice if available
      const currentEffectivePrice =
        currentDiscountPrice ?? currentPrice ?? Infinity;
      const cheapestEffectivePrice =
        cheapestDiscountPrice ?? cheapestPrice ?? Infinity;

      return currentEffectivePrice < cheapestEffectivePrice ? plan : cheapest;
    }, validPlans[0]);

    return {
      planType: cheapestPlan.type,
      prices: cheapestPlan.prices!,
    };
  }

  get plans(): IAppPricingPlan[] {
    let appPricingPlans: IAppPricingPlan[] = [];

    if (this.status === 'DRAFT') {
      appPricingPlans = this.pricingPlans?.plans
        ? this.pricingPlans.plans.map((pricingPlan) =>
            new AppPricingPlan('DRAFT', pricingPlan).toJSON(),
          )
        : [];
    } else {
      appPricingPlans = this.pricingPlans?.plans
        ? this.pricingPlans.plans.reduce(
            (publishedPlans: IAppPricingPlan[], pricingPlan) => {
              const appPlan = this.appPlans?.plans.find(
                (plan) => plan.vendorId === pricingPlan.vendorId,
              );
              if (!appPlan) {
                console.error(
                  `[app-pricing-plans] (plans) failed for appId:${this.appId}, vendorId: ${pricingPlan.vendorId}`,
                );
              } else {
                publishedPlans.push(
                  new AppPricingPlan(
                    'PUBLISHED',
                    pricingPlan,
                    appPlan,
                  ).toJSON(),
                );
              }
              return publishedPlans;
            },
            [],
          )
        : [];
    }
    appPricingPlans.sort((a, b) => {
      if (a.isFree) {
        return -1;
      }
      if (b.isFree) {
        return 1;
      }
      return (
        (a.prices?.discountPrice ?? a.prices?.price!) -
        (b.prices?.discountPrice ?? b.prices?.price!)
      );
    });
    return appPricingPlans;
  }

  toJSON(): IAppsPricingPlans {
    return {
      taxSettings: this.taxSettings,
      currency: this.currency,
      appId: this.appId,
      isExternalPricing: this.isExternalPricing,
      plans: this.plans,
      isFreeApp: this.isFreeApp,
      hasFreePlan: this.hasFreePlan,
      hasDynamicPlan: this.hasDynamicPlan,
      hasMeteredPlanWithNoBaseFee: this.hasMeteredPlanWithNoBaseFee,
      cheapestPlan: this.cheapestPlan,
    };
  }
}
