import { useServices } from '../../contexts';
import { queryApps as queryAppsData } from './search-results-page-data';
import { AppMarketPage } from '../../enums';
import React from 'react';
import { SearchResultsEmptyState } from './empty-search-result-state';
import type {
  QueryApps,
  QueryAppsResponse,
} from '../../components/apps-section';
import { AppsSection } from '../../components/apps-section';
import type { QueryAppsFilter } from '@wix/app-market-services';

export function SearchResultsComponent({
  searchTerm,
  initData,
}: {
  searchTerm: string;
  initData: QueryAppsResponse | null;
}) {
  const services = useServices();
  const pageName = AppMarketPage.SEARCH_RESULT;

  const queryApps: QueryApps = async ({
    filter,
    offset,
  }: {
    filter?: QueryAppsFilter;
    offset?: number;
  }) => {
    const { appGroup, paging } = await queryAppsData({
      searchTerm,
      filter,
      services,
      offset,
      pageName,
    });

    return {
      appGroup,
      paging,
    };
  };

  const EmptyState = ({
    showRecommendedApps,
    isFilterOn,
  }: {
    showRecommendedApps?: boolean;
    isFilterOn?: boolean;
  }) => (
    <SearchResultsEmptyState
      searchTerm={searchTerm}
      showRecommendedApps={showRecommendedApps}
      isFilterOn={isFilterOn}
    />
  );

  return (
    <AppsSection
      origin={pageName}
      queryApps={queryApps}
      EmptyState={EmptyState}
      queryId={searchTerm}
      initData={initData}
    />
  );
}
