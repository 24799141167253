import type {
  SolutionGridLayout,
  WebSolutionExtended,
} from '@wix/app-market-collection-widget';
import {
  SolutionGrid,
  SolutionGridFull,
} from '@wix/app-market-collection-widget';
import * as React from 'react';
import { config } from '../../config';
import { Origin, Path } from '../..';
import {
  getBundleAppSavedPrice,
  isBundleAppAndInstalled,
  isBundleAppAndNotInstalled,
  isBundleAppWithVoucher,
  openOrAddApp,
  shouldRenderAsLink,
  sortSolutionsByInstalled,
} from '../../common/utils';
import { addUrl } from '../../common/addUrl';
import { composer } from '../../component-decorators/composer';
import type { InjectedExperimentsProps } from '@wix/wix-experiments-react';
import { useConfigContext } from '../../config-context';
import type { GUID } from '@wix/bi-logger-app-market-data/v2/types';
import {
  getPathForBi,
  getReferralInfo,
  getRouteForBi,
  getTagName,
} from '../../common/bi-events';
import type { WebSolutionBase } from '@wix/ambassador-marketplace/types';
import { ApiType } from '@wix/ambassador-marketplace/types';
import { isAppPendingConfiguration } from '../../common/is-app-pending-configuration';
import type { MarketplaceStore } from '../../component-decorators/with-marketplace-store';
import { getWixOfferingId } from '../../api/api';
import type { WithTranslation as WithTranslationProps } from 'react-i18next';
import type { IBiData } from '../../types/common/marketplace';
import type { ManagedApp } from '@wix/ambassador-devcenter-managedapps-v1-managed-app/types';
import { IncompleteSetupReason } from '@wix/ambassador-devcenter-managedapps-v1-managed-app/types';

export interface ISolutionGridWrapperProps
  extends Partial<InjectedExperimentsProps>,
    Partial<WithTranslationProps> {
  marketplaceStore?: MarketplaceStore;
  solutions: WebSolutionBase[] | WebSolutionExtended[];
  solutionsBiData?: IBiData[];
  isCompany?: boolean;

  loadMoreSolutions?(): void;

  colSpan?: number;
  isFull?: boolean;
  collimp_id?: GUID;
  referralSectionName?: string;
  sortSolutions?: boolean;
  layout?: SolutionGridLayout;
  hideTagsBadge?: boolean;
  numberOfLines?: number;
}

const getSolutionsBiData = (
  solutions: WebSolutionExtended[],
  marketplaceStore: MarketplaceStore,
): IBiData[] => {
  if (solutions) {
    const { metaSiteId, route, prevRoute, bundleApps } = marketplaceStore;
    const {
      market,
      tagName,
      tagType,
      subCategory,
      referralInfo,
      searchTerm,
      widgetReferral,
    } = getRouteForBi(route, prevRoute);
    return solutions.map(
      (solution: WebSolutionExtended, index: number): IBiData => {
        return {
          msid: metaSiteId,
          appId: solution.id,
          impressionType: 'box',
          index,
          market,
          tagName: tagName.replace(/-/g, '_'),
          tagType,
          subCategory,
          referralInfo,
          searchTerm,
          widgetReferral,
          isBundleAppNotInstalled: isBundleAppAndNotInstalled(
            solution.id,
            bundleApps,
          ),
          isBundleAppInstalled: isBundleAppAndInstalled(
            solution.id,
            bundleApps,
          ),
          referralTag: route?.referralTag,
          referralTagType: getPathForBi(prevRoute?.path),
          referralSubTag: prevRoute?.subCategories?.join(','),
        };
      },
    );
  }
  return [];
};

function enricheSolutions(
  baseUrl: string,
  solutions: WebSolutionExtended[],
  marketplaceStore: MarketplaceStore,
): WebSolutionExtended[] {
  if (!solutions) {
    return [];
  }

  const { installedApps, siteIsPremium, bundleApps } = marketplaceStore;
  return addUrl(
    baseUrl,
    solutions.map((solution: WebSolutionExtended, appIndex: number) => {
      return {
        ...solution,
        appIndex,
        isInstalled:
          installedApps &&
          installedApps.some(
            (installedApp) =>
              installedApp.appId === solution?.id &&
              installedApp?.installationDetails?.incompleteSetupReason !==
                IncompleteSetupReason.PENDING_REQUIRED_INSTALLATIONS,
          ),
        showPremiumLabel:
          !siteIsPremium && solution?.pricing?.isRequiredWixPremium,
        isBundleAppNotInstalled: isBundleAppAndNotInstalled(
          solution?.id,
          bundleApps,
        ),
        isBundleAppInstalled: isBundleAppAndInstalled(solution?.id, bundleApps),
        savedPrice: getBundleAppSavedPrice(solution?.id, bundleApps),
        isVoucherApp: isBundleAppWithVoucher(solution?.id, bundleApps),
        isAppSetupIncomplete: isAppPendingConfiguration(
          installedApps?.find(
            (installedApp: ManagedApp) => installedApp.appId === solution?.id,
          ),
        ),
      };
    }),
  );
}

export const SolutionGridWrapper = composer()
  .withMarketplaceStore()
  .withTranslation()
  .withExperiments()
  .compose(
    ({
      marketplaceStore,
      solutions,
      solutionsBiData,
      colSpan,
      experiments,
      isFull,
      collimp_id,
      referralSectionName,
      sortSolutions,
      layout,
      hideTagsBadge,
      numberOfLines,
      t,
    }: ISolutionGridWrapperProps) => {
      const configContext = useConfigContext();
      const {
        locale,
        i18n,
        baseUrl,
        isMobile,
        goToWixOffering,
        openBMInEditor,
      } = configContext;
      const onButtonClick = async (solution: WebSolutionExtended) => {
        await openOrAddApp(
          solution,
          marketplaceStore,
          biData[solution.appIndex],
          configContext,
          experiments,
          false,
        );
      };
      const onBoxClick = async (solution: WebSolutionExtended) => {
        const isWixOffering = solution.type === ApiType.WIX_OFFERING;

        const navigateToWixOffering = async () => {
          if (config.origin === Origin.STANDALONE) {
            const dest = `https://manage.wix.com/dashboard/{metaSiteId}/wix-offering/app/${solution.id}`;
            const encodedDestination = encodeURIComponent(dest);
            window.open(
              `https://manage.wix.com/account/site-selector?actionUrl=${encodedDestination}`,
            );
          }
          const idResponse = await getWixOfferingId(solution.id);
          const id =
            idResponse.data.appComponents[0].components[0].compData.wixOffering;
          const isPageComponent = 'businessManagerPage' in id;
          if (isPageComponent) {
            const pageComponentId = id.businessManagerPage?.componentId ?? '';
            const appStatus = id.businessManagerPage?.appState ?? '';
            if (config.origin === Origin.BIZMGR) {
              const text = t('wix-offering.toast-text');
              const buttonText = t('wix-offering.toast-cta');
              goToWixOffering(pageComponentId, appStatus, text, buttonText);
            } else {
              const path = appStatus.length
                ? `wix-offering/compId/${pageComponentId}/appState/${appStatus}`
                : `wix-offering/compId/${pageComponentId}`;
              console.log(path);
              openBMInEditor(path);
            }
          }
        };

        if (isWixOffering) {
          await navigateToWixOffering();
        } else {
          config.goto({
            path: Path.WEB_SOLUTION,
            slug: solution.slug,
            referral,
            appIndex: solution.appIndex,
            referralTag,
            collimp_id,
            referralSectionName,
          });
        }
      };
      const biData = solutionsBiData
        ? solutionsBiData
        : getSolutionsBiData(solutions, marketplaceStore);
      for (const i in biData) {
        biData[i].referralSectionName = referralSectionName;
      }
      const referral = getReferralInfo(null, marketplaceStore.route);
      const referralTag = getTagName(marketplaceStore.route);
      let enrichedSolutions: WebSolutionExtended[] = enricheSolutions(
        baseUrl,
        solutions,
        marketplaceStore,
      );
      if (sortSolutions) {
        enrichedSolutions = sortSolutionsByInstalled(enrichedSolutions);
      }
      const solutionGridProps = {
        i18n,
        locale,
        as: shouldRenderAsLink(),
        solutions: enrichedSolutions,
        solutionsBiData: { ...biData },
        isCompany: false,
        onButtonClick,
        onBoxClick,
        colSpan,
        experiments,
        hideCTA: true,
        isMobile,
        layout,
        hideTagsBadge,
        numberOfLines,
        editorType: config.editorType,
      };
      return (
        <div data-testid="solution-grid-wrapper">
          {isFull ? (
            <SolutionGridFull {...solutionGridProps} />
          ) : (
            <SolutionGrid {...solutionGridProps} />
          )}
        </div>
      );
    },
  );
