import type { AppSuggestion } from '@wix/app-market-components';
import type { Services, AutoComplete } from '@wix/app-market-services';
import type Experiments from '@wix/wix-experiments';

export interface AppsSuggestionsFromVespaRequest {
  services: Services;
  experiments: Experiments;
  searchTerm: string;
  limit?: number;
  offset?: number;
  languageCode?: string;
}

export interface AppsSuggestionsFromVespaResponse {
  suggestions: AppSuggestion[];
}

export interface AutocompleteSuggestionsFromVespaRequest {
  services: Services;
  experiments: Experiments;
  searchTerm: string;
  limit?: number;
  offset?: number;
}

export interface AutocompleteSuggestionsFromVespaResponse {
  suggestions: string[];
}

export const getAppsSuggestionsFromVespa = async ({
  services,
  experiments,
  searchTerm,
  limit = 3,
  offset = 0,
  languageCode = 'en',
}: AppsSuggestionsFromVespaRequest): Promise<AppsSuggestionsFromVespaResponse> => {
  const shouldShowAppBadges = experiments.enabled(
    'specs.app-market.AppSuggestionsSearchBadges',
  );
  let suggestions: AppSuggestion[] = [];
  const suggestionsIds = await services
    .searchApps({
      searchTerm,
      paging: {
        limit,
        offset,
      },
    })
    .then((res) => res.appIds)
    .catch(() => []);

  if (suggestionsIds?.length) {
    if (shouldShowAppBadges) {
      const appsData = await services.getAppsByAppIds({
        appIds: suggestionsIds,
        status: 'PUBLISHED',
      });
      const sortedAppsData = suggestionsIds.map((suggestionId) =>
        appsData.findByAppId(suggestionId),
      );

      suggestions =
        sortedAppsData?.map((appData) => {
          return {
            id: appData.id,
            name: appData.name,
            teaser: appData.shortDescription,
            icon: appData.icon,
            slug: appData.slug,
            badges: appData.appBadges,
          };
        }) ?? [];
    } else {
      const [marketListings, apps] = await Promise.all([
        services.marketListing.query({
          appIds: suggestionsIds,
          languageCodes: [languageCode],
          status: 'PUBLISHED',
        }),
        services.apps.query({ appIds: suggestionsIds }),
      ]);

      const sortedMarketListing = suggestionsIds.map((suggestionId) =>
        marketListings?.findByAppId(suggestionId, languageCode),
      );

      suggestions =
        sortedMarketListing?.map((marketListingItem) => {
          return {
            id: marketListingItem.appId,
            name: marketListingItem.appName,
            teaser: marketListingItem.appTeaser,
            icon: marketListingItem.appIcon,
            slug: apps.findByAppId(marketListingItem.appId).slug,
            badges: [],
          };
        }) ?? [];
    }
  }

  return {
    suggestions,
  };
};

export const getAutocompleteSuggestionsFromVespa = async ({
  services,
  experiments,
  searchTerm,
  limit = 3,
  offset = 0,
}: AutocompleteSuggestionsFromVespaRequest): Promise<AutocompleteSuggestionsFromVespaResponse> => {
  const shouldUseProxyAutocomplete = experiments.enabled(
    'specs.app-market.shouldUseProxyAutocomplete',
  );
  const suggestions = shouldUseProxyAutocomplete
    ? (
        await services.getAutoCompleteProxySuggestions({
          searchTerm,
        })
      ).autoCompleteResults
    : searchTerm.length >= 2 // there is a limitation in the search service that the search term length should be at least 2.
    ? await services
        .getAutoCompleteSuggestions({
          searchTerm,
          paging: { limit, offset },
        })
        .then((res: AutoComplete) => res.autoCompleteResults)
    : [];
  return { suggestions };
};
