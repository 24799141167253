import type { SupportedAppStatuses } from '../../types/app';
import type { MeterdBilling } from '../apps-plans';

export type QueryPlans = {
  appIds: string[];
  status?: SupportedAppStatuses;
};

export type PlanDescription = {
  descriptionType: PlanDescriptionType | null;
  benefits?: string[];
};

export enum PlanDescriptionType {
  /** benefits array description */
  BENEFITS = 'BENEFITS',
  /** custom paragraph description */
  CUSTOM_TEXT = 'CUSTOM_TEXT',
}

export type PricingPlan = {
  id: string;
  vendorId: string;
  appId?: string;
  name: string;
  customTitle?: string;
  isVisible: boolean;
  isFree: boolean;
  mostPopular: boolean;
  description: PlanDescription;
  monthlyPrice?: string;
  yearlyPrice?: string;
  oneTimePrice?: string;
  meterdBilling?: MeterdBilling;
};

export type PricingPlans = {
  appId: string;
  plans: PricingPlan[];
  hasDynamicPlan: boolean;
};
