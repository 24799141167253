import type {
  Services,
  IAppsPricingPlans,
  SupportedAppStatuses,
} from '@wix/app-market-services';
import { AppPricingPlans } from '@wix/app-market-services';

export async function getAppPricingPlans({
  services,
  appId,
  status,
}: {
  services: Services;
  appId: string;
  status: SupportedAppStatuses;
}): Promise<IAppsPricingPlans> {
  if (status === 'DRAFT') {
    const pricingPlans = (
      await services.plans.query({
        appIds: [appId],
        status,
      })
    ).findByAppId(appId);

    const appPricingPlans = new AppPricingPlans(
      undefined,
      pricingPlans,
      status,
    ).toJSON();
    return appPricingPlans;
  } else {
    const [appsPlans, pricingPlans] = await Promise.all([
      services.appPlans.list({
        appIds: [appId],
      }),
      (
        await services.plans.query({
          appIds: [appId],
          status,
        })
      ).findByAppId(appId),
    ]);

    const appPricingPlans = new AppPricingPlans(
      appsPlans.findByAppId(appId),
      pricingPlans,
      status,
    ).toJSON();

    return appPricingPlans;
  }
}
