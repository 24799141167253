import type { Plan as PlanV2Type } from '@wix/ambassador-devcenter-plans-v1-plan/types';
import type { PricingPlan, PlanDescription } from './types';
import { PlanDescriptionType } from './types';
import type { MeterdBilling } from '../apps-plans';

export class Plan {
  constructor(private readonly plan: PlanV2Type) {}

  get id(): string {
    return this.plan?.planId ?? '';
  }

  get vendorId(): string {
    return this.plan?.vendorId ?? '';
  }

  get appId(): string {
    return this.plan?.appId ?? '';
  }

  get name(): string {
    return this.plan?.name ?? '';
  }

  get customTitle(): string | undefined {
    return this.plan?.payment?.[0]?.customPaymentTitle ?? undefined;
  }

  get isVisible(): boolean {
    return Boolean(this.plan?.visibleInPages?.length);
  }

  get isFree(): boolean {
    return this.plan?.payment?.length === 0;
  }

  get mostPopular(): boolean {
    return this.plan?.mostPopular ?? false;
  }

  get description(): PlanDescription {
    return {
      descriptionType: this.customTitle
        ? PlanDescriptionType.CUSTOM_TEXT
        : PlanDescriptionType.BENEFITS,
      benefits: this.plan?.description,
    };
  }

  get monthlyPrice(): string | undefined {
    return (
      this.plan?.payment?.find(
        (payment) =>
          payment.recurringOptions?.cycleUnit === 'MONTHLY' &&
          payment.paymentExtensions === 'UNKNOWN_EXTENTIONS',
      )?.amount ?? undefined
    );
  }

  get yearlyPrice(): string | undefined {
    return (
      this.plan?.payment?.find(
        (payment) =>
          payment.recurringOptions?.cycleUnit === 'YEARLY' &&
          payment.paymentExtensions === 'UNKNOWN_EXTENTIONS',
      )?.amount ?? undefined
    );
  }

  get oneTimePrice(): string | undefined {
    return (
      this.plan?.payment?.find((payment) => payment.paymentType === 'ONE_TIME')
        ?.amount ?? undefined
    );
  }

  get meterdBilling(): MeterdBilling | undefined {
    const meteredBillingPayment = this.plan?.payment?.find(
      (payment) => payment.paymentExtensions === 'METERED_BILLING',
    );
    if (!meteredBillingPayment) {
      return undefined;
    }
    return {
      baseFee: String(meteredBillingPayment.amount) ?? '',
      outline:
        meteredBillingPayment.meteredBillingOptions?.additionalInfo ?? '',
    };
  }

  toJSON(): PricingPlan {
    return {
      id: this.id,
      vendorId: this.vendorId,
      appId: this.appId,
      name: this.name,
      customTitle: this.customTitle,
      isVisible: this.isVisible,
      isFree: this.isFree,
      mostPopular: this.mostPopular,
      description: this.description,
      monthlyPrice: this.monthlyPrice,
      yearlyPrice: this.yearlyPrice,
      oneTimePrice: this.oneTimePrice,
      meterdBilling: this.meterdBilling,
    };
  }
}
